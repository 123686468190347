<template>
  <component :is="contractData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="contractData === undefined">
      <div class="alert-body">
        Contrato não encontrado
        <b-link class="alert-link" :to="{ name: 'apps-contract-list' }">
          Lista de contratos
        </b-link>
        for other contracts.
      </div>
    </b-alert>

    <template v-if="contractData">
      <b-row class="invoice-preview">
        <!-- Col: Left (Invoice Container) -->
        <b-col cols="12" xl="12" md="12">
          <b-dropdown
            id="dropdown-1"
            class="text-body h4 float-right"
            size="sm"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="18"
                class="cursor-pointer"
              />
            </template>
            <b-dropdown-item
              v-if="restrictions('button_edit_contract')"
              @click="
                $router.push({
                  name: 'apps-contract-edit',
                  params: { id: contractData.id },
                })
              "
              >{{ $t("message.buttons.edit") }}</b-dropdown-item
            >
            <b-dropdown-item
              v-if="restrictions('button_delete_contract')"
              v-b-modal.modal-delete-contract-confirmation
              >{{ $t("message.buttons.delete") }}</b-dropdown-item
            >
          </b-dropdown>
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <logo />
                    <h3 class="text-primary invoice-logo">BRXMIND</h3>
                  </div>
                  <p class="card-text mb-25">
                    Avenida Sagitário, nº 138 - Sala 409
                  </p>
                  <p class="card-text mb-25">
                    Alphaville, Barueri - CEP: 06473-073
                  </p>
                  <p class="card-text mb-0">+55 11 3090-6629</p>
                </div>

                <!-- Header: Right Content -->
                <div class="mt-md-0 mt-2" style="max-width: 800px">
                  <h4 class="invoice-title">
                    #
                    <span class="invoice-number">{{ contractData.name }}</span>
                  </h4>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">
                      {{ $t("message.contract.date") }}:
                    </p>
                    <p class="invoice-date">
                      {{ localeDate(contractData.date) }}
                    </p>
                  </div>
                  <br />
                  <div>
                    <p>
                      <span v-html="contractData.description"></span>
                    </p>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Invoice Client & Payment Details -->
            <b-card-body v-if="customerData" class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <!-- Col: Invoice To -->
                <b-col cols="12" xl="6" class="p-0">
                  <h6 class="mb-2">{{ $t("message.contract.client") }}:</h6>
                  <h6 class="mb-25">
                    {{ customerData.name }}
                  </h6>
                  <p class="card-text mb-25">
                    {{ customerData.address }}
                  </p>
                  <p class="card-text mb-25">
                    {{ customerData.city }},
                    {{ customerData.district }}
                  </p>
                  <p class="card-text mb-25">
                    {{ customerEmails }}
                  </p>
                  <p class="card-text mb-0">
                    {{ customerPhones }}
                  </p>
                </b-col>

                <!-- Col: Payment Details -->
                <b-col
                  xl="6"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                >
                  <div>
                    <h6 class="mb-2">{{ $t("message.contract.details") }}:</h6>
                    <table>
                      <tbody>
                        <tr v-if="!contractData.international">
                          <td class="pr-1">
                            {{ $t("message.contract.totalValue") }}:
                          </td>
                          <td>
                            {{ $n(contractData.value, "currency") }}
                          </td>
                        </tr>
                        <tr v-else>
                          <td class="pr-1">
                            {{ $t("message.contract.totalValue") }}:
                          </td>
                          <td>
                            {{ contractData.value }}
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            {{ $t("message.contract.status") }}:
                          </td>
                          <td>{{ checkStatus(contractData.status) }}</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            {{ $t("message.contract.projects") }}:
                          </td>
                          <td>{{ projectsList }}</td>
                        </tr>
                        <br />
                        <tr v-if="contractData.international">
                          <td class="pr-1">
                            {{ $t("message.expenses.international") }}:
                          </td>
                          <td>{{ checkTrue(contractData.international) }}</td>
                        </tr>
                        <tr v-if="contractData.international">
                          <td class="pr-1">
                            {{ $t("message.expenses.price") }}:
                          </td>
                          <td>{{ contractData.internationalPrice }}</td>
                        </tr>
                        <tr v-if="contractData.international">
                          <td class="pr-1">
                            {{ $t("message.expenses.Totalprice") }}:
                          </td>
                          <td>
                            {{
                              $n(
                                parseFloat(contractData.value) *
                                  parseFloat(
                                    contractData.internationalPrice
                                  ).toString(),
                                "currency"
                              )
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Invoice Description: Table -->
            <b-table-lite
              responsive
              :items="invoiceData"
              :fields="tableColumns"
            >
              <template #cell(number)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ localeDate(data.item.invoiceExpiryDate) }}
                </b-card-text>
                <b-card-text class="text-nowrap">
                  Nº {{ data.item.number }}
                </b-card-text>
              </template>

              <template #cell(installment)="data">
                <b-card-text>
                  {{ splitArray(data.item.installment) }}
                </b-card-text>
              </template>

              <template #cell(value)="data">
                <b-card-text>
                  {{ $n(data.item.value, "currency") }}
                </b-card-text>
              </template>
            </b-table-lite>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3"
                  order="2"
                  order-md="1"
                >
                  <b-card-text class="mb-0">
                    <span class="font-weight-bold"
                      >{{ $t("message.contract.installments") }}:</span
                    >
                    <span class="ml-75">{{ contractData.installments }}</span>
                  </b-card-text>
                </b-col>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        {{ $t("message.contract.totalValue") }}:
                      </p>
                      {{ $n(contractData.value, "currency") }}
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        {{ $t("message.contract.paidValue") }}:
                      </p>
                      {{ $n(invoicePaidValue, "currency") }}
                    </div>
                    <hr class="my-50" />
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        {{ $t("message.contract.pending") }}:
                      </p>
                      <p class="invoice-total-amount">
                        {{
                          $n(
                            totalMath(contractData.value, invoicePaidValue),
                            "currency"
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />
            <br />
            <b-row>
              <b-col
                style="max-width: 200px; !important"
                v-for="item in filesData"
                :key="item.id"
              >
                <b-card
                  text-variant="white"
                  class="text-center"
                  bg-variant="primary"
                >
                  <feather-icon size="40" icon="FileIcon" />

                  <div class="truncate">
                    <br />
                    <span>{{ item.name }}</span>
                  </div>
                  <br />
                  <b-row>
                    <b-col md="12"
                      ><b-button
                        size="sm"
                        v-if="restrictions('contract_file_download')"
                        @click="downloadFile(item.id, item.name)"
                        ><feather-icon size="20" icon="DownloadCloudIcon"
                      /></b-button>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0"> </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-modal
        id="modal-delete-contract-confirmation"
        modal-class="modal-danger"
        centered
        :title="$t('delete')"
        hide-footer
      >
        <b-card-text> Tem certeza que deseja deletar o registro ? </b-card-text>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <b-button
            v-b-modal.modal-danger
            class="mt-2"
            variant="danger"
            :to="{ name: 'apps-contract-list' }"
            @click="deleteContract()"
          >
            {{ $t("message.buttons.delete") }}
          </b-button>
        </div>
      </b-modal>

      <b-modal
        id="modal-delete-file-confirmation"
        modal-class="modal-danger"
        centered
        title="Deletar"
        hide-footer
      >
        <b-card-text>
          Você tem certeza que deseja deletar o arquivo
          <b>{{ selectedFileName }}</b> ?</b-card-text
        >
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <b-button
            v-b-modal.modal-danger
            class="mt-2"
            variant="danger"
            @click="deleteFile()"
          >
            Deletar
          </b-button>
        </div>
      </b-modal>
    </template>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
  BCardBody,
  BTableLite,
  BCardText,
  VBToggle,
  BDropdown,
  BDropdownItem,
  BFormFile,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import axios from "@axios";
import Ripple from "vue-ripple-directive";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import getHeaderFile from "@core/utils/authFile";
import vSelect from "vue-select";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Logo from "@core/layouts/components/Logo.vue";
import moment from "moment";
import AccessControl from "@core/utils/access-control";
import store from "@/store";
import download from "downloadjs";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    vSelect,
    BCardBody,
    BTableLite,
    BCardText,
    VBToggle,
    Logo,
    BDropdown,
    BDropdownItem,
    BFormFile,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      userOptions: [],
      customerData: [],
      projectData: [],
      contractData: [],
      invoiceData: [],

      customerEmails: "",
      customerPhones: "",
      projectsList: "",

      invoicePaidValue: 0,

      userData: store.state.user.userData,
      paramData: store.state.user.paramData,

      fileFormats: "",
      fileMaxSize: 0,
      file: null,
      filesData: [],
      selectedFileId: "",
      selectedFileName: "",
    };
  },

  watch: {
    locale(val) {
      this.$i18n.locale = val;
    },
  },

  computed: {
    tableColumns() {
      const lang = this.$i18n.locale;
      if (!lang) {
        return [];
      }
      return [
        { key: "number", sortable: false, label: this.getColLabel("number") },
        {
          key: "installment",
          sortable: false,
          label: this.getColLabel("installment"),
        },
        { key: "value", sortable: false, label: this.getColLabel("value") },
        { key: "status", sortable: false, label: this.getColLabel("status") },
      ];
    },
  },

  async created() {
    await this.getContractData();

    await this.getContractFilesData();

    await axios
      .get(`${URL_API}customer/${this.contractData.customerId}`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        this.customerData = response.data;
      });

    if (this.customerData.phoneNumbers) {
      this.customerPhones = this.customerData.phoneNumbers.join(", ");
      this.customerEmails = this.customerData.emailAdresses.join(", ");
    }

    await axios
      .get(`${URL_API}project/contract-list/${this.contractData.projectsId}`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (var i = 0; i < response.data.length; i++) {
          this.projectData.push(response.data[i].name);
        }
      });

    this.projectsList = this.projectData.join(", ");

    await axios
      .get(`${URL_API}invoice/contract/${this.contractData.id}`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        this.invoiceData = response.data;
      });

    var paidList = [];
    for (var i = 0; i < this.invoiceData.length; i++) {
      if (this.invoiceData[i].status === "Fechado") {
        paidList.push(this.invoiceData[i].value);
      }
    }
    var numbers = paidList.map(Number);
    this.invoicePaidValue = numbers.reduce((a, b) => a + b, 0);
  },

  methods: {
    deleteContract() {
      axios({
        method: "delete",
        url: `${URL_API}contract/${this.contractData.id}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        if (!response.data.id && response.data != "") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("noDataAvailable"),
              icon: "XIcon",
              variant: "danger",
              text: this.$i18n.t("noDataAvailableDesc"),
            },
          });
        } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.deleted"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.deleted"),
          },
        });
      }
      });
    },

    downloadFile(id, name) {
      axios({
        method: "get",
        url: `${URL_API}file/download/${id}`,
        headers: getHeader(this.userData),
        responseType: "blob",
      }).then((response) => {
        if (response.data.size === 0) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("Error"),
                icon: "XIcon",
                variant: "warning",
                text: this.$i18n.t("offline"),
              },
            });
          } else {
        const content = response.headers["content-type"];
        download(response.data, name, content);
        }
      });
    },

    getColLabel(colName) {
      return this.$i18n.t("message.contract." + colName);
    },

    restrictions(value) {
      return AccessControl(value);
    },

    checkTrue(value) {
      return this.$i18n.t("message." + value);
    },

    async getContractData() {
      await axios({
        method: "get",
        url: `${URL_API}contract/${router.currentRoute.params.id}`,
        headers: getHeader(this.userData),
      })
        .then((response) => {
          this.contractData = response.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.contractData.value = undefined;
          }
        });
    },

    async getContractFilesData() {
      this.files = [];
      await axios({
        method: "get",
        url: `${URL_API}file/${router.currentRoute.params.id}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        this.filesData = response.data;
      });
    },

    checkStatus(status) {
      if (status) {
        return this.getColLabel("true");
      } else {
        return this.getColLabel("false");
      }
    },

    localeDate(value) {
      if (value) {
        return this.$i18n.d(
          new Date(value.split("-")),
          "short",
          this.$i18n.locale
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    splitArray(array) {
      return array.join(", ");
    },

    formmatedMoney(value) {
      return new Intl.NumberFormat().format(value);
    },

    totalMath(total, paid) {
      return total - paid;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
